import React, {FC} from 'react';
import {Icon} from '@inperium-corp/icons';
import {LanguageLink, Text} from '@inperium-corp/gatsby-core';

import {useTranslation} from '@/core/i18n/useTranslation';

import styles from './BreadCrumbs.scss';

interface IProps {
  breadCrumbs: {text: string; link: string}[];
}

const BreadCrumbs: FC<IProps> = ({breadCrumbs}) => {
  const {t} = useTranslation('categories');
  const withHomeBreadCrumbs = [{text: t('home'), link: ''}, ...breadCrumbs];
  const {length} = withHomeBreadCrumbs;
  return (
    <ol itemScope itemType='https://schema.org/BreadcrumbList' className={styles['breadcrumbs']}>
      {withHomeBreadCrumbs.map(({text, link}, index) => (
        <li itemProp='itemListElement' itemScope itemType='https://schema.org/ListItem' key={text}>
          <LanguageLink itemProp='item' colorScheme='blue-870' to={link} className={styles['breadcrumbs__item']}>
            <span itemProp='name'>
              <Text tagStyle='p3'>{text}</Text>
            </span>
            <meta itemProp='position' content={`${index + 1}`} />
            {index < length - 1 && <Icon icon='caretRight' />}
          </LanguageLink>
        </li>
      ))}
    </ol>
  );
};

export default BreadCrumbs;
