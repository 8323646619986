/* eslint-disable react/no-danger */
import React, {FC} from 'react';
import ReactDOMServer from 'react-dom/server';
import {Text} from '@inperium-corp/gatsby-core';

import AlertEditorComponent from '@/cms/AlertEditorComponent/AlertEditorComponent';

import styles from './ArticleContent.scss';

interface IProps {
  title: string;
  description: string;
  content: JSX.Element;
}

const ArticleContent: FC<IProps> = ({title, description, content}) => {
  // TODO putting in useMemo breaks the build
  // https://github.com/facebook/react/issues/16416
  let searchContent = ReactDOMServer.renderToString(content);
  const regExp = /<div class="custom-block\s(.+)"><div.*>.*<p>(.+)?<\/p>.*<\/div>/gi;
  let alertMatch = regExp.exec(searchContent);
  while (alertMatch != null) {
    const obj = AlertEditorComponent.fromBlock(alertMatch);
    const replacement = AlertEditorComponent.toPreview(obj);
    searchContent = searchContent.replace(alertMatch[0], ReactDOMServer.renderToString(replacement));
    alertMatch = regExp.exec(searchContent);
  }

  return (
    <section className={styles['article-content']}>
      <Text tagStyle='h1'>{title}</Text>
      <Text tagStyle='p'>{description}</Text>
      <div className={styles['article-content__html']} dangerouslySetInnerHTML={{__html: searchContent}} />
    </section>
  );
};

export default ArticleContent;
