import React, {FC, useMemo} from 'react';
import cn from 'classnames';
import GithubSlugger from 'github-slugger';
import {useAnchorObserver} from '@inperium-corp/core';
import {Text} from '@inperium-corp/gatsby-core';

import {useTranslation} from '@/core/i18n/useTranslation';
import styles from './ArticleSidebar.scss';

interface IProps {
  articleContent: string;
}
const ArticleSidebar: FC<IProps> = ({articleContent}) => {
  const {t} = useTranslation();

  const anchorList = useMemo(() => {
    const regEx = new RegExp(/<[h2|h3].*?>(.+?)(?=\<\/.?(?=h2|h3))/gi);
    const hTags = articleContent.match(regEx) || [];
    return hTags.map((tag) => {
      const text = tag.replace(/(<([^>]+)>)/gi, '');
      const link = GithubSlugger.slug(text);
      return {
        text,
        link
      };
    });
  }, [articleContent]);
  const activeAnchor = useAnchorObserver(anchorList.map(({link}) => link));

  return (
    <div className={styles['article-sidebar']}>
      <Text tagStyle='caps3' className={styles['article-sidebar__title']}>
        {anchorList.length > 0 && t('article-sidebar-title')}
      </Text>
      {anchorList?.map(({text, link}) => (
        <a
          key={link}
          href={`#${link}`}
          className={cn(
            styles['article-sidebar__link'],
            link === activeAnchor && styles['article-sidebar__link--active']
          )}
        >
          <Text tagStyle='p2'>{text}</Text>
        </a>
      ))}
    </div>
  );
};

export default ArticleSidebar;
