import React, {FC} from 'react';
import {graphql} from 'gatsby';

import {ICommonPageProps} from '@/core/dtos/ICommonPageProps';
import {IArticleNode} from '@/core/dtos/IArticle';
import {groupArticles} from '@/core/utils/articleUtils';
import {CATEGORIES_TYPE} from '@/core/data/categories';
import Layout from '@/components/Layout/Layout';
import CategoryPage from '@/page-components/CategoryPage/CategoryPage';
import ArticlePage from '@/page-components/ArticlePage/ArticlePage';

interface IProps extends ICommonPageProps {
  data: {
    allMarkdownRemark: {
      edges: {node: IArticleNode}[];
    };
  };
  pageContext: {
    categoryType: string;
    lang: string;
    url: string;
    group?: string;
  };
}

const CategoryTemplate: FC<IProps> = ({data, location, pageContext: {categoryType, url, lang, group}}) => {
  const articleNodes = data.allMarkdownRemark.edges.map(({node}) => node);
  const articleNodesByGroup = groupArticles(articleNodes);
  return (
    <Layout lang={lang} location={location} url={url} seoKey={categoryType} productGroup={group}>
      {categoryType === CATEGORIES_TYPE.SECURITY ? (
        <ArticlePage article={articleNodes[0]} />
      ) : (
        <CategoryPage categoryType={categoryType} articlesByGroup={articleNodesByGroup} group={group} />
      )}
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryArticles($categoryType: String!, $lang: String!) {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: $categoryType}, lang: {in: [$lang]}}}) {
      edges {
        node {
          id
          html
          frontmatter {
            description
            group
            id
            order
            lang
            title
            type
            url
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
