/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {FC} from 'react';

import {IArticleNode} from '@/core/dtos/IArticle';
import {getCategories} from '@/core/data/categories';
import {useTranslation} from '@/core/i18n/useTranslation';
import {sortGroups} from '@/core/utils/articleUtils';

import HeaderSection from './HeaderSection/HeaderSection';
import ArticlesSection from './ArticlesSection/ArticlesSection';

interface IProps {
  categoryType: string;
  articlesByGroup: {[ket: string]: IArticleNode[]};
  group?: string;
}

const CategoryPage: FC<IProps> = ({categoryType, articlesByGroup, group}) => {
  const {t} = useTranslation('categories');
  const category = getCategories(t)
    .filter(({type}) => type === categoryType)
    .pop();

  const articleGroups = sortGroups(Object.keys(articlesByGroup));
  const activeGroup = articleGroups.find((g) => g === group) || articleGroups[0];
  const articles = articlesByGroup[activeGroup];

  return (
    <div>
      <HeaderSection category={category!} group={activeGroup} />
      <ArticlesSection
        category={category!}
        articleGroups={articleGroups}
        articles={articles}
        activeGroup={activeGroup}
      />
    </div>
  );
};

export default CategoryPage;
