import React, {FC} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';
import {ResponsiveImg, Text} from '@inperium-corp/gatsby-core';

import {ICategory} from '@/core/data/categories';
import {getBreadcrumbs} from '@/core/utils/articleUtils';
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs';

import styles from './HeaderSection.scss';

interface IProps {
  category: ICategory;
  group?: string;
}
const HeaderSection: FC<IProps> = ({category, group}) => {
  const breadCrumbs = getBreadcrumbs(category, group);
  return (
    <section className={styles['header-section']}>
      <Container>
        <Row>
          <Col md={5} className={styles['header-section__text-column']}>
            <BreadCrumbs breadCrumbs={breadCrumbs} />
            <Text tagStyle='h3'>{category.title}</Text>
            <Text tagStyle='p'>{category.description}</Text>
          </Col>
          <Col md={6} className='d-none d-md-block offset-md-1'>
            <ResponsiveImg
              src={category.image.link}
              alt={category.name}
              width={category.image.width}
              height={category.image.height}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeaderSection;
