/* eslint-disable react/no-danger */
import React from 'react';
import {Alert, AlertSeverity, lightTheme} from '@inperium-corp/core';
import {ThemeProvider} from 'emotion-theming';

export default {
  // Internal id of the component
  id: 'alert',
  // Visible label
  label: 'Alert',
  // Fields the user need to fill out when adding an instance of the component
  fields: [
    {name: 'type', label: 'type', widget: 'select', options: ['info', 'success', 'warning', 'error']},
    {name: 'text', label: 'Alert text', widget: 'string'}
  ],
  // Pattern to identify a block as being an instance of this component
  pattern: /alert !\((\w+)\) !\((.+)\)/i,
  // Function to extract data elements from the regexp match
  fromBlock(match: string[]) {
    return {
      type: match[1],
      text: match[2]
    };
  },
  // Function to create a text block from an instance of this component
  toBlock(obj: {type: string; text: string}) {
    return `alert !(${obj.type}) !(${obj.text})`;
  },
  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview(obj: {type: string; text: string}) {
    return (
      <ThemeProvider theme={lightTheme}>
        <Alert severity={obj.type as AlertSeverity} className='alert'>
          <span dangerouslySetInnerHTML={{__html: obj.text}} />
        </Alert>
      </ThemeProvider>
    );
  }
};
