import React, {FC, useEffect} from 'react';
import cn from 'classnames';
import {Container, Row, Col} from '@inperium-corp/core';
import {LanguageLink, Text} from '@inperium-corp/gatsby-core';

import {IArticleNode} from '@/core/dtos/IArticle';
import {getGroupName, getGroupUrl, getArticleLink} from '@/core/utils/articleUtils';
import {ICategory} from '@/core/data/categories';
import styles from './ArticlesSection.scss';

interface IProps {
  category: ICategory;
  articleGroups: string[];
  articles: IArticleNode[];
  activeGroup: string;
}

const ArticlesSection: FC<IProps> = ({category, articleGroups, articles, activeGroup}) => {
  useEffect(() => {
    if (window.innerWidth <= 630) {
      const activeGroupLink = document.getElementsByClassName(styles['articles-section__group--active'])[0];
      const groupListsBlock = document.getElementById('groups-list');
      if (activeGroupLink && groupListsBlock) {
        groupListsBlock.scrollTo(activeGroupLink.offsetLeft - 24, 0);
      }
    }
  }, []);

  return (
    <section className={styles['articles-section']}>
      <Container>
        <Row>
          <Col>
            <div className={styles['articles-section__header']} id='groups-list'>
              {articleGroups.map((group, index) => {
                const groupName = getGroupName(group);
                return (
                  <LanguageLink
                    key={group}
                    to={index > 0 ? `${category.link}/${getGroupUrl(group)}` : `${category.link}`}
                    className={cn(
                      styles['articles-section__group'],
                      activeGroup === group && styles['articles-section__group--active']
                    )}
                  >
                    {groupName}
                  </LanguageLink>
                );
              })}
            </div>
          </Col>
        </Row>
        <div className={styles['articles-section__body']}>
          <Row>
            {articles.map((article) => {
              const {
                frontmatter: {title, description, type, group, url}
              } = article;
              return (
                <Col xs={6} md={4} key={title}>
                  <div className={styles['articles-section__article']}>
                    <LanguageLink colorScheme='blue' to={getArticleLink({type, group, url})}>
                      <Text tagStyle='p2' className={styles['articles-section__article-title']}>
                        {title}
                      </Text>
                    </LanguageLink>
                    <Text tagStyle='p2' className={styles['description']}>
                      {description}
                    </Text>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ArticlesSection;
