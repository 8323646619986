/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {FC, useMemo, useState, useEffect} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';

import {IArticleNode, IArticleSearchFields} from '@/core/dtos/IArticle';
import {useTranslation} from '@/core/i18n/useTranslation';
import {getCategories} from '@/core/data/categories';
import {getBreadcrumbs} from '@/core/utils/articleUtils';
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs';

import ArticleContent from './ArticleContent/ArticleContent';
import ArticleSidebar from './ArticleSidebar/ArticleSidebar';
import ArticleNextSteps from './ArticleNextSteps/ArticleNextSteps';

import styles from './ArticlePage.scss';

interface IProps {
  article: IArticleNode;
}
const ArticlePage: FC<IProps> = ({article}) => {
  const {html, frontmatter} = article;
  const {t, i18n} = useTranslation('categories');
  const category = getCategories(t)
    .filter(({type}) => type === frontmatter.type)
    .pop();
  const breadCrumbs = getBreadcrumbs(category!, frontmatter.group, article);
  // eslint-disable-next-line react/no-danger
  const htmlContent = useMemo(() => <div dangerouslySetInnerHTML={{__html: html}} />, [html]);

  // This effect waits for lunr index to be loaded.
  const [lunrIndexLoaded, setLunrIndexLoaded] = useState(false);
  useEffect(() => {
    window.__LUNR__.__loaded.then(() => setLunrIndexLoaded(true));
  }, []);

  // This effect gets next posts from lunr index data.
  const [nextStepsPosts, setNextStepsPosts] = useState<IArticleSearchFields[]>([]);
  useEffect(() => {
    const lunrIndex = window.__LUNR__[i18n.language];
    if (lunrIndexLoaded && lunrIndex && frontmatter.next_steps) {
      const indexedArticles = {};
      Object.keys(lunrIndex.store).forEach((articleId: string) => {
        const article = lunrIndex.store[articleId];
        indexedArticles[article.frontmatter__id] = article;
      });
      const nextSteps = frontmatter.next_steps.map((articleId) => indexedArticles[articleId]);
      setNextStepsPosts(nextSteps);
    }
  }, [lunrIndexLoaded, frontmatter.next_steps, i18n.language]);

  return (
    <div className={styles['article-page']}>
      <Container>
        <Row>
          <Col md={8}>
            <BreadCrumbs breadCrumbs={breadCrumbs} />
            <ArticleContent title={frontmatter.title} description={frontmatter.description} content={htmlContent} />
            {nextStepsPosts && nextStepsPosts.length > 0 && <ArticleNextSteps nextSteps={nextStepsPosts} />}
          </Col>
          <Col md={4} className='d-none d-sm-block'>
            <ArticleSidebar articleContent={html} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ArticlePage;
