import React, {FC} from 'react';
import {LanguageLink, Text} from '@inperium-corp/gatsby-core';
import {IArticleSearchFields} from '@/core/dtos/IArticle';

import {getArticleLink} from '@/core/utils/articleUtils';
import {useTranslation} from '@/core/i18n/useTranslation';
import styles from './ArticleNextSteps.scss';

interface IProps {
  nextSteps: IArticleSearchFields[];
}
const ArticleNextSteps: FC<IProps> = ({nextSteps}) => {
  const {t} = useTranslation();
  return (
    <div className={styles['next-steps']}>
      <Text tagStyle='caps3' className={styles['next-steps__title']}>
        {t('article-next-steps')}
      </Text>
      <div className={styles['next-steps__links']}>
        {nextSteps.map(({title, type, group, url}) => (
          <LanguageLink key={`${type}-${url}`} to={getArticleLink({type, group, url})} colorScheme='blue'>
            <Text tagStyle='p2'>{title}</Text>
          </LanguageLink>
        ))}
      </div>
    </div>
  );
};

export default ArticleNextSteps;
